<template>
  <div class="grid md:grid-cols-3 hidden md:grid gap-3 linksGrid">
    <div
      v-for="(property, i) in properties"
      :key="i"
      :style="{
        backgroundImage: `url(${$api}/uploads/${property.featuredImage})`,
      }"
      @click="$router.push(`/properties/${property._id}`)"
      style="background-size: cover; background-position: center"
      class="p-5 relative grid-item cursor-pointer"
    >
      <div
        style="background-color: rgba(0, 0, 0, 0.65)"
        class="
          p-5
          absolute
          flex flex-col
          items-center
          justify-center
          top-0
          bottom-0
          left-0
          right-0
        "
      >
        <h3
          class="opacity-100 text-xs font-thin text-white uppercase text-center"
        >
          {{ property.location }}
        </h3>
        <h2 class="opacity-100 text-3xl font-thin text-white text-center">
          {{ property.name }}
        </h2>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 md:hidden gap-3 linksGridMobile">
    <div
      v-for="(property, i) in properties.slice(0, 4)"
      :key="i"
      :style="{
        backgroundImage: `url(${$api}/uploads/${property.featuredImage})`,
      }"
      style="
        background-size: cover;
        background-position: centerl;
        height: 320px;
      "
      @click="$router.push(`/properties/${property._id}`)"
      class="p-5 relative grid-item-mobile cursor-pointer"
    >
      <div
        style="background-color: rgba(0, 0, 0, 0.65)"
        class="
          p-5
          absolute
          flex flex-col
          items-center
          justify-center
          top-0
          bottom-0
          left-0
          right-0
        "
      >
        <h3
          class="opacity-100 text-xs font-thin text-white uppercase text-center"
        >
          {{ property.location }}
        </h3>
        <h2
          class="
            opacity-100
            text-2xl
            font-thin
            text-white text-center
            px-8
            py-2
          "
        >
          {{ property.name }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinksGrid",
  props: {
    properties: Array,
  },
};
</script>

 


<style scoped lang="scss">
* {
  font-family: "Brown";
  font-weight: 100;
}

@font-face {
  font-family: "Brown";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Brown"), url("/assets/Brown-Regular.ttf") format("ttf"),
    url("/assets/Brown-Regular.ttf") format("ttf");
}

.linksGrid {
  max-width: 1200px;
  margin: 0 auto;
  grid-auto-rows: minmax(300px, auto);

  div:nth-child(2) {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
  }

  div:nth-child(4) {
    grid-column: 1 / span 1;
    grid-row: 2 / span 2;
  }

  @media (max-width: 1030px) {
    grid-auto-rows: minmax(200px, auto);
  }

  .grid-item {
    transition: transform 0.2s;
    overflow: hidden;

    div {
      transition: transform 0.5s, background-color 0.8s;
    }
  }

  .grid-item:hover {
    transform: scale(1.02);

    div {
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.8) !important;
    }
  }
}

.linksGridMobile {
  max-width: 1200px;

  margin: 0 auto;
  grid-auto-rows: minmax(300px, auto);

  @media (max-width: 1030px) {
    grid-auto-rows: minmax(200px, auto);
  }

  .grid-item-mobile {
    transition: transform 0.2s;
    overflow: hidden;

    div {
      transition: transform 0.5s, background-color 0.8s;
    }
  }

  .grid-item-mobile:hover {
    transform: scale(1.02);

    div {
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.8) !important;
    }
  }
}
</style>